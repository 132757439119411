<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 bg-white">
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("EditContactPerson") }}
      </h2>
    </div>

    <b-card>
      <!-- form -->
      <validation-observer ref="editUser">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <b-col sm="6">
              <b-form-group :label="$t('Nombre')" label-for="account-name">
                <b-form-input v-model="user.name" name="name" :placeholder="$t('Nombre')" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="$t('Apellidos')" label-for="account-surname">
                <b-form-input v-model="user.surname" :placeholder="$t('Apellidos')" name="surname" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" name="Email" :rules="requiredEmail">
                <b-form-group :label="$t('Email')" label-for="account-e-mail">
                  <b-form-input v-model="user.email" :disabled="disableEmail" name="email" :placeholder="$t('Email')" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="$t('Telefono')" rules="max:9|min:9">
                <b-form-group :label="$t('Telefono')" label-for="account-phone">
                  <b-input-group>
                    <template #prepend>
                      <vue-country-code :key="codePhone" :default-country="codePhone" :disabled-fetching-country="true"
                        @onSelect="onSelect" />
                    </template>
                    <b-form-input v-model="phone" type="number" name="company" :placeholder="$t('Telefono')" />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="$t('Rol')" rules="required">
                <b-form-group :label="$t('Rol')" label-for="account-rols">
                  <v-select v-model="role" label="display_name" :filterable="false" :searchable="false"
                    :options="selectRoles" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <b-form-group :label="$t('SubClientes')" label-for="subclients">
                <SubClientSelect id="selectSubClient" :value="selectedSubClient" @input="addSubClient" :disabled="true"
                  :nocreate="true" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="canChangePassword">
            <b-col sm="6">
              <b-form-group>
                <b-form-checkbox v-model="changePassword" value="true" plain>
                  <strong>{{ $t("CambiarContrasenya") }}</strong>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group v-if="changePassword" label-for="account-password">
                <b-form-input v-model="password" name="password" :placeholder="$t('Contrasenya')" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ $t("Guardar") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import VueCountryCode from "vue-country-code-select";
import SubClientSelect from "@/components/subClients/select/SubClientSelect.vue";

export default {
  components: {
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueCountryCode,

    SubClientSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      companies: "",
      clients: [],
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      disableUsername: "",
      disableEmail: "",
      canChangePassword: "",
      requiredEmail: "",
      requiredUsername: "",

      userPrepare: false,
      disabledClients: true,
      changePassword: false,
      password: "",
      role: {},
      codePhone: "",
      codePhoneSave: "",

      selectedSubClient: null,
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      user: "users/getUser",
      userSelectRoles: "users/getRoles",
      selectCompanies: "companies/getListCompanies",
      selectClients: "clients/getSelectClients",
      departmentsSelect: "departments/getSelectDepartments",
      currentRole: "auth/getRole",
    }),
    selectRoles() {
      const arrayOutput = [];
      this.userSelectRoles.forEach((element) => {
        if (element.name !== "admin_cliente") {
          arrayOutput.push(element);
        } else {
          arrayOutput.push(element);
        }
      });
      return arrayOutput;
    },
    phone: {
      get() {
        if (
          this.user &&
          (this.user.phone == null || this.user.phone === "null")
        ) {
          return "";
        }
        return this.user.phone;
      },
      set(val) {
        this.user.phone = val;
        return val;
      },
    },
  },
  watch: {
    companies() {
      this.clients = [];

      if (this.companies) {
        this.searchClients({
          company: this.companies.id,
          client: this.currentClient,
        });
        this.disabledClients = false;
      } else {
        this.disabledClients = true;
      }
    },
    clients() {
      this.departments = [];
      const aux = this;
      if (this.clients.length > 0) {
        aux.selectDepartments({ clients: this.clients });
      } else {
        this.setSelectDepartments([]);
      }
    },
  },
  methods: {
    ...mapActions({
      edit: "users/edit",
      getUser: "users/getUser",
      searchCompanies: "companies/searchCompanies",
      searchClients: "clients/selectClients",
      selectDepartments: "departments/selectDepartments",
      getRoles: "users/getRoles",
    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
    addSubClient(value) {
      this.selectedSubClient = value;
    },
    setData() {
      if (this.user) {
        if (this.user.companies[0]) {
          this.companies = this.user.companies[0];
          this.searchClients(this.user.companies[0].id);
        }
        if (this.user.roles) {
          this.role = this.user.roles[0];
        }
        if (this.user.code_phone) {
          this.codePhone = JSON.parse(this.user.code_phone).iso;
        } else {
          this.codePhone = "ES";
          this.codePhoneSave = JSON.stringify({
            iso: "ES",
            code: 34,
          });
        }
        if (this.user.email) {
          this.disableUsername = true;
          this.disableEmail = false;
          this.requiredEmail = `required|email|emailUnique:${this.user.id}`;
          this.requiredUsername = "";
        }
        if (this.user.app_access == 0) {
          this.canChangePassword = false;
        } else {
          this.canChangePassword = true;
        }

        if (this.user.subclients.length > 0) {
          this.selectedSubClient = this.user.subclients;
        }

        this.userPrepare = true;
      }
    },
    setClients() {
      if (this.user && this.user.clients) {
        this.clients = this.user.clients;
      }
    },
    setDepartments() {
      if (this.user && this.user.departments) {
        if (this.user.departments[0]) {
          this.user.departments.forEach((element) => {
            this.departments.push(element.id);
          });
        }
      }
    },
    handleSubmit() {
      this.$refs.editUser.validate().then((success) => {
        if (success) {
          const { id } = this.user;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, user: formData });
          }
        }
      });
    },
    createFormData() {
      // const data = this.$refs.images.getFormData();
      const data = new FormData();

      if (this.companies) {
        data.append("companies[]", this.companies.id);
      }

      if (this.clients.length > 0) {
        this.clients.forEach((element) => {
          data.append("clients[]", element.id);
        });
      } else {
        data.append("clients", "");
      }

      data.append("name", this.user.name);
      data.append("surname", this.user.surname);
      data.append("email", this.user.email);
      data.append("phone", this.phone);
      data.append("code_phone", this.codePhoneSave);

      // data.append("lang", this.user.lang);
      // data.append("username", this.user.username);
      // data.append("dni", this.user.dni);

      if (this.changePassword === "true") {
        data.append("password", this.password);
      }

      // if (this.role) {
      //   data.append("roles[]", this.role.id);
      // }

      // if (this.selectedSubClient) {
      //   data.append("subclients[]", this.selectedSubClient.id);
      // }

      return data;
    },
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = iso2;
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      });
    },
  },
  async created() {
    await this.getUser(this.$route.params.id);
    await this.setData();
    await this.searchCompanies();
    await this.setClients();
    await this.setDepartments();
    await this.getRoles();
  },
  destroyed() {
    this.$store.commit("users/setUser", {});
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
