<template>
  <b-row align-v="center" no-gutters>
    <b-col :cols="nocreate ? '12' : '10'">
      <div>
        <v-select :value="value" :options="selectCategories" :filterable="false" label="name" @open="onOpen"
          @close="onClose" @search="(query) => (search = query)" :multiple="multiple"
          @input="(val) => $emit('input', val)" :readonly="readonly" :disabled="disabled" class="base-action-select">
          <template #list-footer>
            <li v-show="hasNextPage" ref="load" class="loader">
              {{ $t("LoadingMoreOptions") }}
            </li>
          </template>
        </v-select>
      </div>
    </b-col>
    <b-col cols="2" v-if="nocreate == false">
      <b-button variant="primary" class="text-nowrap rounded-end d-inline-block base-select-add-button"
        @click="creationModalVisible = true">
        <feather-icon icon="PlusIcon" size="19" />
      </b-button>
    </b-col>
    <SubClientCreationModal v-model="creationModalVisible" @subclient-created-successfully="handleSubClientCreadted" />
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import subclientsApi from "@/api/subclients-api";
import SubClientCreationModal from "@/components/subClients/modal/SubClientCreationModal.vue";

export default {
  components: {
    vSelect,

    SubClientCreationModal,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nocreate: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      creationModalVisible: false,
      observer: null,
      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: "",
      selectCategories: [],
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
    }),
    hasNextPage() {
      let output = true;
      if (this.lastPage === this.currentPage) {
        output = false;
      }
      if (this.selectCategories.length === 0) {
        output = false;
      }

      return output;
    },
  },
  watch: {
    async search() {
      this.currentPage = 1;
      this.selectCategories = [];
      await this.chargeData(true);
    },
  },
  async mounted() {
    await this.chargeData();
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    ...mapActions({
      list: "subclients/getListSubClients",
    }),
    handleSubClientCreadted() {
      this.creationModalVisible = false;
      this.chargeData(false, true);
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async chargeData(ifSearch = false, clear = false) {
      if (clear) {
        this.currentPage = 1;
        this.selectCategories = [];
      }
      let cliente = this.currentClient ? this.currentClient.id : "";

      await subclientsApi
        .list(cliente, this.currentPage, this.pageLength, this.search)
        .then(
          (response) => {
            this.selectCategories = ifSearch
              ? response.data.data
              : this.selectCategories.concat(response.data.data);
            this.lastPage = response.data.meta.last_page;
          },
          () => {
            console.log("ERROR");
          }
        );
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.currentPage += 1;
        await this.chargeData();
        ul.scrollTop = scrollTop;
      }
    },
  },
};
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}

.form-control:disabled {
  background-color: #f7f5f0 !important;
}
</style>
